<template>
  <v-container class="pt-0">
    <v-card class="card__dispositivos" style="border-radius: 10px">
      <v-card-title class="px-4 d-flex justify-space-between" style="font-weight: bold">
        <v-row>
          <v-col cols="12" sm="5">
            Todos los dispositivos
          </v-col>
          <v-col cols="12" sm="7" class="d-flex justify-center justify-sm-end">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              class="dispositivos__buscador mr-sm-10"
              outlined
              dense
              color="#C2C2C2"
              placeholder="Buscar dispositivos"
            ></v-text-field>
            <!--            <div class="d-flex align-center">-->
            <!--              <v-icon dense class="grey&#45;&#45;text px-2 hidden-xs-only">mdi-filter</v-icon>-->
            <!--              <h5 class="grey&#45;&#45;text font-weight-regular hidden-xs-only">Filtros</h5>-->
            <!--            </div>-->
          </v-col>
        </v-row>
        <!--        <v-spacer></v-spacer>-->
      </v-card-title>
      <v-card-title v-if="markers.length !== 0" class="px-0">
        <Mapa :markers="markers" class="mapa" :key="markers.length + '-marker'"></Mapa>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dispositivos"
        :search="search"
        hide-default-footer
        mobile-breakpoint="0"
        :item-class="calcularColorFila"
        @current-items="setDispositivosFound"
        class="dispositivos-datatable"
        :items-per-page="-1"
      >
        <template v-slot:item="{ item }">
          <tr class="dispositivo-item"
              :class="{'dispositivo-item--danger': item.estado === 'MEDIO' || item.estado === 'ALTO'}"
              @click="$router.push({name: 'Sensores', params: { codigo: item.codigo}})">
            <td>
              <v-row :ref="item.codigo">
                <v-col cols="2" class="hidden-xs-only">
                  <v-avatar
                    size="36px"
                  >
                    <v-icon class="pr-sm-3 px-md-0">{{ item.icono ? item.icono : 'mdi-thermometer' }}</v-icon>
                  </v-avatar>
                </v-col>
                <v-col>
                  <p class="dispositivo-item__title mb-1">{{ item.nombre }}</p>
                  <p class="dispositivo-item__subtitle mb-0">{{ item.updated | moment('from', 'now', true) }}</p>
                </v-col>
              </v-row>
            </td>
            <td>
              <p class="dispositivo-item__title mb-1">{{ item.ubicacion_linea_1 }} - {{ item.ubicacion_linea_2 }}</p>
              <p class="dispositivo-item__subtitle mb-0">{{ item.ubicacion_linea_2 }}</p>
            </td>

            <td class="text-center">
              <p class="dispositivo-item__title mb-0">{{ item.sensores.length }}</p>
            </td>


            <td class="text-center">
              <v-img :src="item.conectado ? '/img/icons/transmision-icon.svg': '/img/icons/transmision-off-icon.svg'" max-width="16px" class="mx-auto"></v-img>
            </td>

            <td>
              <v-chip size="24px" class="dispositivo-item__state" :color="getColor(item.estado)">
                {{ item.estado }}
              </v-chip>
              <!--              <v-icon :color="getColor(item.estado)">mdi-map-marker</v-icon>-->
            </td>

            <td>
              <!--          <v-icon class="grey&#45;&#45;text pr-1 hidden-sm-and-up">{{item.tipo}}</v-icon>-->
              <v-menu
                top
                :offset-y="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" @click.stop class="icon-color">mdi-dots-vertical</v-icon>
                </template>

                <v-card class="options-card" @click="$router.push({name: 'Resumen', params: { codigo: item.codigo}})">
                  Ir a resumen
                </v-card>
              </v-menu>
            </td>
          </tr>

        </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AppBar from '../../components/layout/AppBar'
import Mapa from '../../components/maps/Mapa'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Dispositivos',
  components: {
    Mapa,
    AppBar
  },
  data () {
    return {
      title: 'Dispositivos',
      search: '',
      dispositivosFound: [],
      headers: [
        {
          text: 'Detalle del dispositivo',
          align: 'start',
          value: 'nombre',
        },
        { text: 'Ubicación', value: 'ubicacion', },
        { text: 'N° de sensores', value: 'sensores', align: 'center', },
        { text: 'Transmisión', value: 'transmisores', align: 'center' },
        { text: 'Estado', value: 'estado', },
        { text: '', value: 'actions', sortable: false, },
      ],
    }
  },

  methods: {
    ...mapActions(['setTitleAppBar']),
    ...mapActions('iot', ['fetchDispositivos']),

    getColor (estado) {
      switch (estado) {
        case 'NORMAL':
          return '#29CC97'
        case 'MEDIO':
          return '#FEC400'
        case 'ALTO':
          return '#FF3737'
      }
    },

    calcularIconoMarcador (estado) {
      switch (estado) {
        case 'NORMAL':
          return 'https://storage.googleapis.com/django_app/map-markers/verde.png'
        case 'MEDIO':
          return 'https://storage.googleapis.com/django_app/map-markers/naranja.png'
        case 'ALTO':
          return 'https://storage.googleapis.com/django_app/map-markers/rojo.png'
      }
    },

    calcularColorFila (item) {
      if (item.codigo == this.markerSelected.id) {
        return 'color-fila-selected'
      } else {
        if (item.estado != 'NORMAL') {
          return 'color-fila-rojo'
        }
        return 'color-fila-default'
      }
    },

    setDispositivosFound (e) {
      this.dispositivosFound = e
    }

  },
  watch: {
    markerSelected () {
      if (this.markerSelected) {
        this.$refs[`${this.markerSelected.id}`].scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
  },
  computed: {
    ...mapGetters(['titleAppBar']),
    ...mapState('iot', ['dispositivos', 'markerSelected']),
    markers () {
      return this.dispositivosFound.map(({ codigo, latitud, longitud, nombre, estado }) => {
        return {
          id: codigo,
          title: nombre,
          icon: this.calcularIconoMarcador(estado),
          position: {
            lat: parseFloat(latitud),
            lng: parseFloat(longitud),
          },
        }
      })
    },
  },
  mounted () {
    this.setTitleAppBar(this.title)
  }
}
</script>

<style lang="scss" scoped>
.dispositivos__buscador {
  max-width: 340px;
  max-height: 30px;
  border-radius: 8px;
  color: #C2C2C2;
  font-weight: normal !important;
}

/*@media screen and (max-width: 599px) {*/

/*  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:first-child) > td:first-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:first-child) > th:first-child {*/
/*    border-top: thin solid rgba(0, 0, 0, 0.12);*/
/*  }*/

/*  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {*/
/*    display: none !important;*/

/*  }*/
/*}*/
.card__dispositivos {
  margin: auto;
}

.mapa {
  height: 425px;
}

.color-fila-rojo {
  background-color: #ffebeb;
}

.color-fila-selected {
  background-color: #eeeeee;
}

.color-fila-default {
  background-color: #ffffff;
}

.subtitle--text {
  color: #C5C7CD;
  font-weight: 400;
}

.dispositivos-datatable ::v-deep table thead th {
  border-bottom: 2px solid #DFE0EB !important;
}

.dispositivos-datatable ::v-deep th {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9FA2B4 !important;
}

.dispositivos-datatable ::v-deep tr {
  height: 96px;
  min-height: 96px;
  cursor: pointer;
}

.dispositivo-item {

  &--danger {
    background-color: rgba(255, 55, 55, 0.1);
  }

  &__title {
    font-family: 'Mulish';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
  }

  &__subtitle {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
  }

  &__state {
    height: 24px;
    font-weight: 700;
    font-size: 11px !important;
    line-height: 14px !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF !important;
    padding: 6px 12px;
  }
}

.options-card {
  border: 1px solid #DCDDE7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 7px 33px;

}

</style>
