<template>
  <CargarMapa
    :configMapa="configMapa"
    apiKey="AIzaSyBCLXkXXqi4H-xpzhu4NgvJGhQ5fDpUvHo"
  >

    <template slot-scope="{ google, map }">
      <Marcadores
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
    </template>

  </CargarMapa>

</template>

<script>
  import CargarMapa from './CargarMapa'
  import Marcadores from './Marcadores'
  import { configMapa } from "@/plugins/maps";

  export default {
    name: 'Mapa',
    props: {
      markers: Array
    },
    components: {
      CargarMapa,
      Marcadores
    },

    data () {
      return {
      }
    },

    computed: {
      configMapa () {
        return {
          ...configMapa,
          center: this.mapCenter
        }
      },

      mapCenter () {
        return this.markers.length === 0 ? {
          lat: 19.15,
          lng: 18.5,
        } : this.markers[0].position
      }
    },

  }
</script>

<style scoped>

</style>
