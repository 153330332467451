<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Marcadores',
    props: {
      google: {
        type: Object,
        required: true
      },
      map: {
        type: Object,
        required: true
      },
      marker: {
        type: Object,
        required: true
      }
    },
    mounted () {
      const { Marker } = this.google.maps

      const marker = new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        title: this.marker.title,
        icon: this.marker.icon,
      })

      marker.addListener("click", () => {
        this.$router.push({name: 'Sensores', params: { codigo: this.marker.id}})
      });

    },
    methods:{
      ...mapActions('iot', ['markerSelected'])
    },
    render() {}
  }
</script>
